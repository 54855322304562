import { Box, Flex, GridItem, SimpleGrid, VStack } from '@chakra-ui/react'
import type { Ratio, SummaryBoxesButtonWidgetItemType } from 'ecosystem'
import {
  GrandAspectRatio,
  GrandHeading,
  GrandImage,
  GrandLink,
  GrandNextLink,
  GrandOverlay,
  HTMLArticle
} from 'ui'
import { hrefFromAppPaths } from 'shared-utils'
import { useAppPaths } from 'ui/hooks'
import { WIDGET_GROUP_CN } from '../../constants'
import { summaryBoxesButtonCn as cn } from './constants'

type SummaryBoxesButtonWidgetItemProps = SummaryBoxesButtonWidgetItemType & {
  aspectRatio?: Ratio
  isReversed?: boolean
}

const SummaryBoxesButtonWidgetItem = ({
  title,
  sub,
  imgUrl,
  pathObj,
  isExternal,
  isReversed,
  btnLabel,
  aspectRatio = '4:3'
}: SummaryBoxesButtonWidgetItemProps) => {
  const isLink = Boolean(pathObj) && !btnLabel
  const appPaths = useAppPaths()

  return (
    <Box
      as={isLink ? GrandLink : 'div'}
      className={cn('item')}
      w="full"
      {...(isLink
        ? {
            href: pathObj ? hrefFromAppPaths(pathObj, appPaths) : '/',
            isExternal,
            'aria-label': title,
            _hover: {
              textDecoration: 'none'
            }
          }
        : {})}>
      <SimpleGrid
        className={cn(WIDGET_GROUP_CN.ITEM_WRAPPER)}
        columns={{
          base: 1,
          md: 10
        }}
        spacing={{
          base: 6,
          md: 10
        }}
        w="full">
        {Boolean(imgUrl) && (
          <GridItem
            className={cn('item_img_wrapper')}
            colSpan={{
              base: 1,
              md: 6
            }}
            order={isReversed ? 2 : 1}
            position="relative"
            w="full">
            <GrandAspectRatio
              borderRadius="md"
              breakpoints={{ base: '1:1', sm: aspectRatio }}
              overflow="hidden">
              <GrandImage
                alt={title || ''}
                fill
                objectFit="cover"
                sizes="(min-width: 52em) 50vw, 100vw"
                src={imgUrl ?? ''}
              />

              <GrandOverlay className={cn(WIDGET_GROUP_CN.ITEM_OVERLAY)} />
            </GrandAspectRatio>
          </GridItem>
        )}

        <GridItem
          alignItems="stretch"
          as={VStack}
          className={cn('item_content-wrapper')}
          colSpan={{
            base: 1,
            md: 4
          }}
          justifyContent="space-between"
          order={isReversed ? 1 : 2}
          spacing={4}>
          <VStack alignItems="stretch" spacing={4}>
            {title ? (
              <GrandHeading
                className={cn(WIDGET_GROUP_CN.ITEM_TITLE)}
                containerProps={{
                  className: cn(WIDGET_GROUP_CN.ITEM_TITLE_CONTAINER),
                  alignItems: 'flex-start'
                }}
                decorationProps={{
                  className: cn(WIDGET_GROUP_CN.ITEM_TITLE_DECORATION)
                }}
                fontSize={{
                  base: '2xl',
                  md: '4xl'
                }}
                headingTag="h4"
                title={title}
                withDecoration
              />
            ) : null}

            {sub ? (
              <HTMLArticle className={cn(WIDGET_GROUP_CN.ITEM_TEXT_WRAPPER)} content={sub} />
            ) : null}
          </VStack>

          {btnLabel && pathObj ? (
            <Flex
              className={cn(WIDGET_GROUP_CN.ITEM_LINK_LABEL_CONTAINER)}
              justifyContent="flex-end"
              w="full">
              <GrandNextLink
                className={cn(WIDGET_GROUP_CN.ITEM_LINK_LABEL)}
                href={hrefFromAppPaths(pathObj, appPaths)}
                isButton
                isExternal={isExternal}>
                {btnLabel}
              </GrandNextLink>
            </Flex>
          ) : null}
        </GridItem>
      </SimpleGrid>
    </Box>
  )
}

export default SummaryBoxesButtonWidgetItem

'use client'
import type { FC } from 'react'
import { Grid, GridItem } from '@chakra-ui/react'
import type { ImageGalleryWidgetType, StorefrontWidget } from 'ecosystem'
import { GrandNextLink, GrandSection, ResponsiveImage } from 'ui'
import { hrefFromAppPaths } from 'shared-utils'
import { useAppPaths } from 'ui/hooks'
import type { WidgetImplementation } from '../../utils'

type WidgetImageGalleryProps = WidgetImplementation<StorefrontWidget<ImageGalleryWidgetType>>

const WidgetImageGallery: FC<WidgetImageGalleryProps> = ({ widgetId, widget: { widget } }) => {
  const items = widget.items
  const appPaths = useAppPaths()

  return (
    <GrandSection py={0} sectionId={widgetId} title="">
      <Grid
        className="image-gallery__wrapper"
        gap={{ base: 0, md: 1 }}
        templateColumns={{
          base: `repeat(${widget.templateColumnsMobile || 1}, 1fr)`,
          md: `repeat(${widget.templateColumns || 1}, 1fr)`
        }}>
        {items.map((item) => (
          <GridItem
            className="image-gallery__item"
            colSpan={item.colSpan || 1}
            key={item.id}
            py={{
              base: 2,
              md: 0
            }}
            rowSpan={item.rowSpan || 1}
            {...(item.pathObj
              ? {
                  as: GrandNextLink,
                  href: item.pathObj ? hrefFromAppPaths(item.pathObj, appPaths) : '/',
                  isExternal: item.isExternal
                }
              : {})}
            alignItems="stretch"
            display="flex">
            <ResponsiveImage
              alt={item.alt}
              h={item.rowSpan > 1 ? 'auto' : 300}
              minH={300}
              src={item.imgUrl}
            />
          </GridItem>
        ))}
      </Grid>
    </GrandSection>
  )
}

export default WidgetImageGallery

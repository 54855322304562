import type { FC } from 'react'
import type { GridItemProps } from '@chakra-ui/react'
import { GridItem, VStack } from '@chakra-ui/react'
import type { SquareWidgetItemType } from 'ecosystem'
import type { GrandImageProps } from 'ui/common/GrandImage'
import {
  GrandAspectRatio,
  GrandImage,
  GrandNextLink,
  GrandOverlay,
  HTMLArticle,
  ResponsiveTextBox
} from 'ui'
import { hrefFromAppPaths, truncateString } from 'shared-utils'
import { useUIGlobalsContext } from 'ui/lib/ui-globals/hooks'
import { useAppPaths } from 'ui/hooks'
import { WIDE_WIDGET_CN, wideWidgetCn as cn } from './constants'

interface IWidgetItemProps extends GridItemProps {
  item: SquareWidgetItemType
  imageProps?: Partial<GrandImageProps>
}

const WidgetItem: FC<IWidgetItemProps> = ({ item, imageProps, ...props }) => {
  const { defaultImgSrc } = useUIGlobalsContext()
  const appPaths = useAppPaths()

  return (
    <GrandAspectRatio
      as={GridItem}
      borderRadius="sm"
      breakpoints={{ base: '16:9' }}
      className={cn(WIDE_WIDGET_CN.ITEM_WRAPPER)}
      cursor={{
        base: 'default',
        md: item.pathObj ? 'pointer' : 'default'
      }}
      overflow="hidden"
      {...props}>
      <GrandNextLink
        alignItems="center"
        aria-label={item.title}
        data-campaignid={item.id}
        display="flex"
        h="full"
        href={item.pathObj ? hrefFromAppPaths(item.pathObj, appPaths) : '/'}
        isExternal={item.isExternal}
        justifyContent="center">
        <GrandOverlay className={cn(WIDE_WIDGET_CN.ITEM_OVERLAY)} />

        <GrandImage
          alt={item.title || 'widget item'}
          fill
          objectFit="cover"
          quality={90}
          sizes="(min-width: 52em) 100vw, 50vw"
          src={item.imgUrl || defaultImgSrc}
          {...imageProps}
        />

        <VStack h="full" justify="center" position="relative" px={2} spacing={0} w="full">
          <ResponsiveTextBox
            as="h4"
            className={cn(WIDE_WIDGET_CN.ITEM_TITLE)}
            color="text.light"
            fontSize={{
              base: '3xl',
              md: 'xl'
            }}>
            {item.title?.toUpperCase()}
          </ResponsiveTextBox>

          {item.sub ? (
            <HTMLArticle
              className={cn(WIDE_WIDGET_CN.ITEM_TEXT_WRAPPER)}
              color="text.light"
              content={truncateString(item.sub, 500)}
              fontSize="md"
              fontWeight="normal"
              textAlign="center"
              zIndex={2}
            />
          ) : null}
        </VStack>
      </GrandNextLink>
    </GrandAspectRatio>
  )
}

export default WidgetItem

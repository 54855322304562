import { type FlexProps } from '@chakra-ui/react'
import {
  ContentContainer,
  GrandAspectRatio,
  GrandDivider,
  GrandNextLink,
  GrandText,
  ResponsiveTextBox
} from 'ui'
import GrandOverlay from 'ui/common/GrandOverlay'
import DynamicHeroImage from 'ui/common/DynamicHeroImage'
import { useEffect, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { type HeroCarouselWidgetItemType, type Ratio } from 'ecosystem'
import { useAppPaths } from 'ui/hooks'
import { hrefFromAppPaths } from 'shared-utils'
import { HERO_CAROUSEL_WIDGET_CN as WIDGET_CN, heroCarouselCn as cn } from './constants'

type HeroCarouselWidgetItemProps = HeroCarouselWidgetItemType & {
  aspectRatio?: Ratio
  aspectRatioMobile?: Ratio
}

export const HeroCarouselWidgetItem = ({
  aspectRatio,
  aspectRatioMobile,
  alt,
  mobileImgUrl,
  imgUrl,
  title,
  slogan,
  sub,
  pathObj,
  isExternal,
  ctaLabel,
  headingTag,
  priority
}: HeroCarouselWidgetItemProps) => {
  const [deviceImgUrl, setDeviceImgUrl] = useState<string | null>(() =>
    mobileImgUrl ? null : imgUrl
  )

  const appPaths = useAppPaths()

  useEffect(() => {
    if (mobileImgUrl && isMobileOnly) {
      setDeviceImgUrl(mobileImgUrl)
    } else {
      setDeviceImgUrl(imgUrl)
    }
  }, [imgUrl, mobileImgUrl])

  const renderContent = () => (
    <GrandAspectRatio<FlexProps, 'w'>
      className={cn(WIDGET_CN.ITEM_WRAPPER)}
      color="text.light"
      breakpoints={{
        base: aspectRatioMobile ?? aspectRatio ?? '4:3',
        sm: aspectRatio ?? '33:10'
      }}>
      <ContentContainer
        alignItems="center"
        bottom={0}
        className={cn(WIDGET_CN.ITEM_CONTENT_CONTAINER)}
        direction="column"
        h="100%"
        justifyContent="center"
        left={0}
        position="absolute"
        px={{
          base: 2,
          md: 8
        }}
        right={0}
        spacing={6}
        textAlign="center"
        zIndex={2}>
        {slogan ? (
          <GrandText
            className={cn(WIDGET_CN.ITEM_SLOGAN)}
            fontSize={['md', 'lg']}
            textStyle="slogan">
            {slogan}
          </GrandText>
        ) : null}

        {title ? (
          <ResponsiveTextBox
            as={headingTag ? headingTag : 'h1'}
            className={cn(WIDGET_CN.ITEM_TITLE)}
            color="text.light"
            fontSize={['4xl', '5xl']}
            fontWeight="medium"
            w="100%">
            {title}
          </ResponsiveTextBox>
        ) : null}

        {(title || sub) && (
          <GrandDivider
            borderColor="text.light"
            borderWidth={1}
            className={cn(WIDGET_CN.ITEM_DIVIDER)}
            opacity={1}
            w="40%"
          />
        )}

        {!!sub && (
          <GrandText className={cn(WIDGET_CN.ITEM_SUB)} fontSize={['lg', 'xl']}>
            {sub}
          </GrandText>
        )}

        {ctaLabel && pathObj && (
          <GrandNextLink
            aria-label={ctaLabel}
            className={cn(WIDGET_CN.ITEM_CTA)}
            href={hrefFromAppPaths(pathObj, appPaths)}
            isButton
            isExternal={isExternal}>
            {ctaLabel}
          </GrandNextLink>
        )}
      </ContentContainer>

      <GrandOverlay
        className={cn(WIDGET_CN.ITEM_OVERLAY)}
        {...(deviceImgUrl ? {} : { backdropFilter: 'blur(10px)' })}
      />

      {!!(deviceImgUrl ?? imgUrl) && (
        <DynamicHeroImage
          alt={alt ?? 'hero slide'}
          src={deviceImgUrl ?? imgUrl}
          objectFit="cover"
          priority={priority ?? false}
          {...(deviceImgUrl
            ? {}
            : {
                quality: 1,
                sizes: '32vw'
              })}
        />
      )}
    </GrandAspectRatio>
  )

  if (pathObj && !ctaLabel) {
    return (
      <GrandNextLink href={hrefFromAppPaths(pathObj, appPaths)} isExternal={isExternal}>
        {renderContent()}
      </GrandNextLink>
    )
  }

  return renderContent()
}

import type { GridItemProps } from '@chakra-ui/react'
import { GridItem, VStack } from '@chakra-ui/react'
import GrandAspectRatio from 'ui/common/GrandAspectRatio'
import type { CircleLinkWidgetItemType, Ratio } from 'ecosystem'
import { GrandHeading, GrandImage, GrandNextLink, HTMLArticle } from 'ui'
import { useUIGlobalsContext } from 'ui/lib/ui-globals/hooks'
import { useAppPaths } from 'ui/hooks'
import { hrefFromAppPaths } from 'shared-utils'
import { WIDGET_GROUP_CN } from '../../constants'
import { circleLinkCn as cn } from './constants'

type CircleLink1WidgetItemProps = GridItemProps & {
  item: CircleLinkWidgetItemType
  aspectRatio?: Ratio
}

const CircleLink1WidgetItem = ({
  item,
  aspectRatio = '33:10',
  ...props
}: CircleLink1WidgetItemProps) => {
  const { title, sub, imgUrl, pathObj, isExternal } = item
  const isLink = Boolean(pathObj)
  const { defaultImgSrc } = useUIGlobalsContext()
  const appPaths = useAppPaths()

  return (
    <GridItem
      as={isLink ? GrandNextLink : 'div'}
      className={cn(WIDGET_GROUP_CN.ITEM)}
      {...(isLink
        ? {
            href: pathObj ? hrefFromAppPaths(pathObj, appPaths) : '/',
            'aria-label': title || '',
            isExternal
          }
        : {})}
      w="full"
      {...props}>
      <VStack w="100%">
        {title ? (
          <GrandHeading
            className={cn(WIDGET_GROUP_CN.ITEM_TITLE)}
            textAlign="center"
            title={title}
          />
        ) : null}

        <GrandAspectRatio breakpoints={{ base: aspectRatio }} maxWidth="210px" width="full">
          <GrandImage
            alt={title || ''}
            fill
            objectFit="contain"
            quality={90}
            src={imgUrl || defaultImgSrc}
          />
        </GrandAspectRatio>

        {sub ? (
          <HTMLArticle
            className={cn(WIDGET_GROUP_CN.ITEM_TEXT_WRAPPER)}
            content={sub}
            display={{
              base: 'none',
              md: 'initial'
            }}
            pt={2}
            textAlign="center"
          />
        ) : null}
      </VStack>
    </GridItem>
  )
}

export default CircleLink1WidgetItem

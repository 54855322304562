import { Flex, GridItem, SimpleGrid, VStack } from '@chakra-ui/react'
import type { ArticleBoxesWidgetItemType, Ratio } from 'ecosystem'
import {
  GrandAspectRatio,
  GrandHeading,
  GrandImage,
  GrandLink,
  GrandNextLink,
  GrandOverlay,
  HTMLArticle
} from 'ui'
import { hrefFromAppPaths } from 'shared-utils'
import { useAppPaths } from 'ui/hooks'
import { WIDGET_GROUP_CN } from '../../constants'
import { articleBoxesCn as cn } from './constants'

type ArticleBoxesWidgetItemProps = ArticleBoxesWidgetItemType & {
  aspectRatio?: Ratio
  isReversed?: boolean
}

const ArticleBoxesWidgetItem = ({
  title,
  sub,
  imgUrl,
  pathObj,
  isExternal,
  isReversed,
  label: btnLabel,
  aspectRatio = '16:9'
}: ArticleBoxesWidgetItemProps) => {
  const isLink = Boolean(pathObj && !btnLabel)
  const appPaths = useAppPaths()

  return (
    <SimpleGrid
      as={isLink ? GrandLink : 'div'}
      className={cn(WIDGET_GROUP_CN.ITEM_WRAPPER)}
      {...(isLink
        ? {
            href: pathObj ? hrefFromAppPaths(pathObj, appPaths) : '/',
            isExternal,
            'aria-label': title,
            _hover: {
              textDecoration: 'none'
            }
          }
        : {})}
      columns={{
        base: 1,
        md: 10
      }}
      spacing={{
        base: 6,
        md: 10
      }}
      w="full">
      {imgUrl ? (
        <GridItem
          className={cn('item_img_wrapper')}
          colSpan={{
            base: 1,
            md: 3
          }}
          order={isReversed ? 2 : 1}
          position="relative"
          w="full">
          <GrandAspectRatio
            borderRadius="lg"
            breakpoints={{ base: '16:9', md: aspectRatio }}
            overflow="hidden">
            <GrandImage
              alt={title || ''}
              fill
              objectFit="cover"
              sizes="(min-width: 52em) 30vw, 100vw"
              src={imgUrl}
            />

            <GrandOverlay className={cn(WIDGET_GROUP_CN.ITEM_OVERLAY)} />
          </GrandAspectRatio>
        </GridItem>
      ) : null}

      <GridItem
        alignItems="stretch"
        as={VStack}
        className={cn('item_content-wrapper')}
        colSpan={{
          base: 1,
          md: 7
        }}
        justifyContent="space-between"
        order={isReversed ? 1 : 2}
        spacing={4}>
        <VStack alignItems="stretch" className={cn(WIDGET_GROUP_CN.ITEM_TEXT_WRAPPER)} spacing={4}>
          {title ? (
            <GrandHeading
              className={cn(WIDGET_GROUP_CN.ITEM_TITLE)}
              containerProps={{ mb: 0 }}
              fontSize={{
                base: 'xl',
                md: '2xl'
              }}
              fontWeight="medium"
              headingTag="h4"
              title={title}
              withDecoration={false}
            />
          ) : null}

          {sub ? <HTMLArticle className={cn('item_content')} content={sub} /> : null}
        </VStack>

        {btnLabel && pathObj ? (
          <Flex justifyContent="flex-end" w="full">
            <GrandNextLink
              bg="brand.yellow"
              border="none"
              color="default"
              href={hrefFromAppPaths(pathObj, appPaths)}
              isButton
              isExternal={isExternal}>
              {btnLabel}
            </GrandNextLink>
          </Flex>
        ) : null}
      </GridItem>
    </SimpleGrid>
  )
}

export default ArticleBoxesWidgetItem

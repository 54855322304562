import type { StackProps } from '@chakra-ui/react'
import { Flex, useBreakpointValue, VStack } from '@chakra-ui/react'
import type { Ratio, SummaryBoxesButtonWidgetItemType } from 'ecosystem'
import { hrefFromAppPaths, truncateString } from 'shared-utils'
import {
  GrandAspectRatio,
  GrandHeading,
  GrandImage,
  GrandLink,
  GrandOverlay,
  HTMLArticle
} from 'ui'
import { useAppPaths } from 'ui/hooks'
import { WIDGET_GROUP_CN } from '../../constants'
import { summaryBoxesButtonCn as cn } from './constants'

type SummaryBoxesButton1WidgetItemProps = StackProps &
  SummaryBoxesButtonWidgetItemType & {
    aspectRatio?: Ratio
  }

const SummaryBoxesButton1WidgetItem = ({
  title,
  sub = '',
  imgUrl,
  pathObj,
  isExternal,
  btnLabel,
  aspectRatio = '16:9',
  ...props
}: SummaryBoxesButton1WidgetItemProps) => {
  const isLink = Boolean(pathObj) && !btnLabel
  const content = useBreakpointValue({
    base: truncateString(sub, 120),
    sm: truncateString(sub, 200),
    md: sub
  })
  const appPaths = useAppPaths()

  return (
    <VStack
      alignItems="stretch"
      as={isLink ? GrandLink : 'div'}
      className={cn(WIDGET_GROUP_CN.ITEM_WRAPPER)}
      fontSize={{
        base: 'xs',
        sm: 'sm'
      }}
      justifyContent="space-between"
      spacing={4}
      w="full"
      {...(isLink
        ? {
            href: pathObj ? hrefFromAppPaths(pathObj, appPaths) : '/',
            isExternal,
            'aria-label': title,
            _hover: {
              textDecoration: 'none'
            }
          }
        : {})}
      {...props}>
      <VStack
        spacing={{
          base: 4,
          md: 8
        }}
        w="full">
        {title && imgUrl ? (
          <VStack
            className={cn('item_title_img_wrapper')}
            spacing={{
              base: 2,
              md: 4
            }}
            w="full">
            {title ? (
              <GrandHeading
                className={cn(WIDGET_GROUP_CN.ITEM_TITLE)}
                containerProps={{
                  className: cn(WIDGET_GROUP_CN.ITEM_TITLE_CONTAINER),
                  my: 0,
                  mr: 0
                }}
                decorationProps={{
                  className: cn(WIDGET_GROUP_CN.ITEM_TITLE_DECORATION)
                }}
                fontSize={{
                  base: 'md',
                  md: '2xl'
                }}
                fontWeight="medium"
                headingTag="h4"
                title={title}
                withDecoration={false}
              />
            ) : null}

            {imgUrl ? (
              <GrandAspectRatio
                borderRadius="md"
                breakpoints={{ base: '4:3', sm: aspectRatio }}
                className={cn('item_img_wrapper')}
                overflow="hidden">
                <GrandImage
                  alt={title || ''}
                  fill
                  objectFit="cover"
                  sizes="(min-width: 52em) 50vw, 100vw"
                  src={imgUrl}
                />

                <GrandOverlay className={cn(WIDGET_GROUP_CN.ITEM_OVERLAY)} />
              </GrandAspectRatio>
            ) : null}
          </VStack>
        ) : null}

        {content ? (
          <Flex className={cn(WIDGET_GROUP_CN.ITEM_TEXT_WRAPPER)} w="full">
            <HTMLArticle className={cn('item_content')} content={content} />
          </Flex>
        ) : null}
      </VStack>

      {btnLabel && pathObj ? (
        <Flex
          className={cn(WIDGET_GROUP_CN.ITEM_LINK_LABEL_CONTAINER)}
          justifyContent="flex-start"
          w="full">
          <GrandLink
            _hover={{
              textDecoration: 'none'
            }}
            className={cn(WIDGET_GROUP_CN.ITEM_LINK_LABEL)}
            fontWeight="semibold"
            href={hrefFromAppPaths(pathObj, appPaths)}
            isExternal={isExternal}
            textDecoration="underline">
            {btnLabel}
          </GrandLink>
        </Flex>
      ) : null}
    </VStack>
  )
}

export default SummaryBoxesButton1WidgetItem

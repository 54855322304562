import { Box, Flex, GridItem, SimpleGrid, VStack } from '@chakra-ui/react'
import type { Ratio, SummaryBoxesButtonWidgetItemType } from 'ecosystem'
import {
  GrandAspectRatio,
  GrandHeading,
  GrandImage,
  GrandLink,
  GrandNextLink,
  GrandOverlay,
  HTMLArticle
} from 'ui'
import { useAppPaths } from 'ui/hooks'
import { hrefFromAppPaths } from 'shared-utils'
import { WIDGET_GROUP_CN } from '../../constants'
import { summaryBoxesButtonCn as cn } from './constants'

type SummaryBoxesButton2WidgetItemProps = SummaryBoxesButtonWidgetItemType & {
  aspectRatio?: Ratio
  isReversed?: boolean
}

const SummaryBoxesButton2WidgetItem = ({
  title,
  sub,
  imgUrl,
  pathObj,
  isExternal,
  isReversed,
  btnLabel,
  aspectRatio = '4:3'
}: SummaryBoxesButton2WidgetItemProps) => {
  const isLink = Boolean(pathObj) && !btnLabel
  const appPaths = useAppPaths()

  return (
    <Box
      as={isLink ? GrandLink : 'div'}
      className={cn('item')}
      w="full"
      {...(isLink
        ? {
            href: pathObj ? hrefFromAppPaths(pathObj, appPaths) : '/',
            isExternal,
            'aria-label': title,
            _hover: {
              textDecoration: 'none'
            }
          }
        : {})}>
      <SimpleGrid
        className={cn(WIDGET_GROUP_CN.ITEM_WRAPPER)}
        columns={{
          base: 1,
          md: imgUrl && (title || sub || btnLabel) ? 2 : 1
        }}
        spacing={{
          base: 6,
          md: 10
        }}
        w="full">
        {imgUrl ? (
          <GridItem
            className={cn('item_img_wrapper')}
            colSpan={1}
            order={isReversed ? 2 : 1}
            position="relative"
            w="full">
            <GrandAspectRatio
              borderRadius="md"
              breakpoints={{ base: '1:1', sm: aspectRatio }}
              overflow="hidden">
              <GrandImage
                alt={title || ''}
                fill
                objectFit="cover"
                sizes="(min-width: 52em) 50vw, 100vw"
                src={imgUrl}
              />

              <GrandOverlay className={cn(WIDGET_GROUP_CN.ITEM_OVERLAY)} />
            </GrandAspectRatio>
          </GridItem>
        ) : null}

        {title || sub || btnLabel ? (
          <GridItem
            alignItems="stretch"
            as={VStack}
            className={cn('item_content-wrapper')}
            colSpan={1}
            justifyContent="space-between"
            order={isReversed ? 1 : 2}
            spacing={10}>
            <VStack alignItems="stretch" spacing={4}>
              {title ? (
                <GrandHeading
                  className={cn(WIDGET_GROUP_CN.ITEM_TITLE)}
                  containerProps={{
                    className: cn(WIDGET_GROUP_CN.ITEM_TITLE_CONTAINER)
                  }}
                  decorationProps={{
                    className: cn(WIDGET_GROUP_CN.ITEM_TITLE_DECORATION)
                  }}
                  fontSize="2xl"
                  fontWeight="medium"
                  headingTag="h4"
                  title={title}
                  withDecoration={false}
                />
              ) : null}

              {sub ? (
                <HTMLArticle className={cn(WIDGET_GROUP_CN.ITEM_TEXT_WRAPPER)} content={sub} />
              ) : null}
            </VStack>

            {btnLabel && pathObj ? (
              <Flex
                className={cn(WIDGET_GROUP_CN.ITEM_LINK_LABEL_CONTAINER)}
                justifyContent={{
                  base: 'center',
                  md: 'flex-end'
                }}
                w="full">
                <GrandNextLink
                  bg="brand.blue"
                  className={cn(WIDGET_GROUP_CN.ITEM_LINK_LABEL)}
                  href={hrefFromAppPaths(pathObj, appPaths)}
                  isButton
                  isExternal={isExternal}>
                  {btnLabel}
                </GrandNextLink>
              </Flex>
            ) : null}
          </GridItem>
        ) : null}
      </SimpleGrid>
    </Box>
  )
}

export default SummaryBoxesButton2WidgetItem

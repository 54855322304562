'use client'

import { type HeroCarouselWidgetType, type StorefrontWidget } from 'ecosystem'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation, Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { chakra } from '@chakra-ui/react'
import GrandSection from 'ui/storefront/layout/GrandSection'
import type { WidgetImplementation } from '../../utils'
import { HERO_CAROUSEL_WIDGET_CN as WIDGET_CN, heroCarouselCn as cn } from './constants'
import { HeroCarouselWidgetItem } from './HeroCarouselWidgetItem'

const ChakraSwiper = chakra(Swiper)

type HeroCarouselWidgetProps = WidgetImplementation<StorefrontWidget<HeroCarouselWidgetType>>

export const HeroCarouselWidget = ({ widget, widgetId }: HeroCarouselWidgetProps) => {
  const {
    title,
    items,
    isWrapped,
    itemsAspectRatio,
    itemsAspectRatioMobile,
    pagination,
    autoplay,
    navigation,
    loop,
    slidesPerView = 1
  } = widget.widget

  const renderSwiper = () => (
    <ChakraSwiper
      className={cn(WIDGET_CN.ITEMS_WRAPPER)}
      h="100%"
      modules={[Pagination, Navigation, Autoplay]}
      pagination={pagination}
      loop={loop}
      autoplay={autoplay}
      navigation={navigation}
      slidesPerView={slidesPerView}
      sx={{
        position: 'relative !important',
        '&': {
          '--swiper-pagination-bottom': '0',
          '--swiper-pagination-color': 'var(--chakra-colors-primary)'
        },
        '.swiper-button-next, .swiper-button-prev': {
          h: '100%',
          top: 0,
          mt: 0,
          width: 'auto',
          p: '3',
          color: 'text.light',
          opacity: 0.6,
          ':hover': {
            opacity: 1
          },
          '&.swiper-button-disabled': {
            opacity: 0.6
          }
        },
        '.swiper-button-next': {
          right: 0
        },
        '.swiper-button-prev': {
          left: 0
        }
      }}
      w="100%">
      {items.map((item) => (
        <SwiperSlide key={item.id}>
          <HeroCarouselWidgetItem
            aspectRatio={itemsAspectRatio}
            aspectRatioMobile={itemsAspectRatioMobile}
            {...item}
          />
        </SwiperSlide>
      ))}
    </ChakraSwiper>
  )

  if (!isWrapped) {
    return renderSwiper()
  }

  return (
    <>
      {(Boolean(items.length) || Boolean(title)) && (
        <GrandSection
          className={cn(WIDGET_CN.SECTION)}
          contentContainerProps={{
            className: cn(WIDGET_CN.SECTION_CONTAINER),
            spacing: 0,
            gap: 10
          }}
          sectionId={widgetId}
          titleProps={{
            title,
            className: cn(WIDGET_CN.SECTION_TITLE),
            decorationProps: {
              className: cn(WIDGET_CN.SECTION_TITLE_DECORATION)
            },
            containerProps: {
              className: cn(WIDGET_CN.SECTION_TITLE_CONTAINER)
            }
          }}>
          {renderSwiper()}
        </GrandSection>
      )}
    </>
  )
}

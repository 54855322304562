'use client'
import type { FC } from 'react'
import type { GridItemProps, StackProps } from '@chakra-ui/react'
import { Flex, VStack } from '@chakra-ui/react'
import type { HTMLArticleProps, IGrandHeadingProps } from 'ui'
import { GrandHeading, GrandNextLink, GrandOverlay, HTMLArticle, GrandImage } from 'ui'
import { hrefFromAppPaths, truncateString } from 'shared-utils/utils'
import type { CircleLinkWidgetItemType } from 'ecosystem'
import { useAppPaths } from 'ui/hooks'
import { WIDGET_GROUP_CN } from '../../constants'
import { circleLinkCn as cn } from './constants'

export interface ICircleWidgetItemProps extends GridItemProps {
  item: CircleLinkWidgetItemType
  wrapperProps?: StackProps
  imgWrapperProps?: StackProps
  textWrapperProps?: StackProps
  titleProps?: Omit<IGrandHeadingProps, 'title'>
  textProps?: Omit<HTMLArticleProps, 'content'>
}

const CircleLinkWidgetItem: FC<ICircleWidgetItemProps> = ({
  item,
  wrapperProps,
  imgWrapperProps,
  textWrapperProps,
  titleProps,
  textProps,
  ...props
}) => {
  const appPaths = useAppPaths()

  return (
    <Flex
      alignItems="center"
      borderColor="text.default"
      borderWidth={{
        base: '1px',
        lg: 0
      }}
      className={cn(WIDGET_GROUP_CN.ITEM)}
      cursor={{
        base: 'default',
        md: item.pathObj ? 'pointer' : 'default'
      }}
      justifyContent="center"
      p={2}
      w={{
        base: 'auto',
        md: '200px'
      }}
      {...props}>
      <GrandNextLink
        aria-label={item.title}
        href={item.pathObj ? hrefFromAppPaths(item.pathObj, appPaths) : '/'}
        isExternal={item.isExternal}>
        <VStack
          p={0}
          spacing={{
            base: 0,
            lg: 4
          }}
          w="full"
          {...wrapperProps}>
          <VStack
            borderRadius="50%"
            className={cn(WIDGET_GROUP_CN.ITEM_WRAPPER)}
            display={{
              base: 'none',
              lg: 'flex'
            }}
            h={{
              base: 0,
              md: '200px'
            }}
            ml="auto"
            mr="auto"
            overflow="hidden"
            position="relative"
            spacing={0}
            w={{
              base: 0,
              md: '200px'
            }}
            {...imgWrapperProps}>
            {Boolean(item.imgUrl) && (
              <GrandImage
                alt={item.title || ''}
                fill
                objectFit="cover"
                quality={100}
                sizes="500px"
                src={item?.imgUrl ?? ''}
              />
            )}

            <GrandOverlay className={cn(WIDGET_GROUP_CN.ITEM_OVERLAY)} />
          </VStack>

          <VStack className={cn(WIDGET_GROUP_CN.ITEM_TEXT_WRAPPER)} w="full" {...textWrapperProps}>
            {item.title ? (
              <GrandHeading
                as="h4"
                className={cn(WIDGET_GROUP_CN.ITEM_TITLE)}
                containerProps={{
                  m: 0,
                  maxH: '20px',
                  w: 'full'
                }}
                cursor="inherit"
                fontSize={{
                  base: 'sm',
                  md: 'md'
                }}
                fontWeight="medium"
                isTruncated
                noOfLines={1}
                textAlign="center"
                title={item.title}
                whiteSpace={{ base: 'nowrap', md: 'normal' }}
                zIndex={2}
                {...titleProps}
              />
            ) : null}

            {item.sub ? (
              <HTMLArticle
                className={cn('item_sub')}
                content={truncateString(item.sub, 500)}
                display={{
                  base: 'none',
                  lg: 'block'
                }}
                minH="50px"
                textAlign="center"
                w="full"
                {...textProps}
              />
            ) : null}
          </VStack>
        </VStack>
      </GrandNextLink>
    </Flex>
  )
}

export default CircleLinkWidgetItem

import type {
  ArticleBoxesWidgetType,
  ArticleSizedBoxesWidgetType,
  BubbleLinkWidgetType,
  CampaignHeroWidgetType,
  CircleLinkWidgetType,
  ColoredItemsWidgetType,
  ContentBoxWidgetType,
  DynamicWidgetProps,
  HeroWidgetType,
  ImageGalleryWidgetType,
  ImageWidgetType,
  JoinedWidgetType,
  MapWidgetType,
  PartialRecord,
  SquareWidgetType,
  StorefrontWidget,
  StorefrontWidgetTypeHandler,
  SummaryBoxesButtonWidgetType,
  SummaryBoxesWidgetType,
  TwoColumnsWidgetType,
  WidgetCTAWidgetType,
  DividerWidgetType,
  EmbeddedVideoWidgetType,
  VideoWidgetType,
  AccordionWidgetType,
  CampaignWidgetType,
  HeroCarouselWidgetType
} from 'ecosystem'
import type { ReactElement } from 'react'
import {
  BubbleLinkWidget,
  CircleLinkWidgetHandler,
  HeroCarouselWidget,
  SummaryBoxesWidgetHandler
} from '../components'
import HeroWidget from '../components/HeroWidget'
import CampaignHeroWidget from '../components/CampaignHeroWidget'
import WideWidgetHandler from '../components/WideWidget/WideWidgetHandler'
import TwoColumnsWidget from '../components/TwoColumnsWidget'
import WidgetCTA from '../components/WidgetCTA'
import ContentBox from '../components/ContentBox'
import WidgetImageGallery from '../components/WidgetImageGallery/WidgetImageGallery'
import ImageWidget from '../components/ImageWidget'
import WidgetAccordionItem from '../components/WidgetAccordionItem'
import WidgetVideoSection from '../components/WidgetVideoSection'
import WidgetEmbeddedVideoSection from '../components/WidgetEmbeddedVideoSection'
import { MapWidget } from '../components/MapWidget'
import CircleLink1Widget from '../components/CircleLink1Widget'
import SummaryBoxesButtonWidget from '../components/SummaryBoxesButtonWidget'
import SummaryBoxesButton1Widget from '../components/SummaryBoxesButton1Widget'
import SummaryBoxesButton2Widget from '../components/SummaryBoxesButton2Widget'
import ArticleSizedBoxesWidget from '../components/ArticleSizedBoxesWidget'
import ArticleBoxesWidget from '../components/ArticleBoxesWidget'
import ArticleBoxes1Widget from '../components/ArticleBoxes1Widget'
import ArticleBoxes2Widget from '../components/ArticleBoxes2Widget'
import ColoredItemsWidget from '../components/ColoredItemsWidget'
import DividerWidget from '../components/DividerWidget'
import PlaceholderWidget from '../components/PlaceholderWidget'
import JoinedWidget from '../components/JoinedWidget'

export interface WidgetFactoryOptions<Extensions extends string | number | symbol> {
  dynamicProps?: DynamicWidgetProps
  extensions?: PartialRecord<StorefrontWidgetTypeHandler | Extensions, ReactElement>
}

export interface WidgetImplementation<Widget, CustomProps = never> {
  widgetId: string
  widget: Widget
  dynamicProps?: DynamicWidgetProps
  customProps?: CustomProps
}

export const sharedWidgetsFactory = <Extensions extends string | number | symbol>(
  widget: StorefrontWidget<unknown>,
  opts: WidgetFactoryOptions<Extensions>
) => {
  const dictionary: Record<StorefrontWidgetTypeHandler, ReactElement> = {
    HERO: (
      // This component will work with aspect ratio and with no aspect ratio
      <HeroWidget
        widget={widget as StorefrontWidget<HeroWidgetType>}
        widgetId={(widget as StorefrontWidget<HeroWidgetType>).widget.name}
      />
    ),

    HERO_CAROUSEL: (
      <HeroCarouselWidget
        widget={widget as StorefrontWidget<HeroCarouselWidgetType>}
        widgetId={(widget as StorefrontWidget<HeroCarouselWidgetType>).widget.name}
      />
    ),

    CAMPAIGN_HERO: (
      // This component will work with aspect ratio and with no aspect ratio
      <CampaignHeroWidget
        widget={widget as StorefrontWidget<CampaignHeroWidgetType>}
        widgetId={(widget as StorefrontWidget<CampaignHeroWidgetType>).widget.name}
      />
    ),

    CAMPAIGN_WIDGET: (
      <WideWidgetHandler
        priority
        widget={widget as StorefrontWidget<CampaignWidgetType>}
        widgetId={(widget as StorefrontWidget<CampaignWidgetType>).widget.name}
      />
    ),

    SQUARE_LINK: (
      <WideWidgetHandler
        widget={widget as StorefrontWidget<SquareWidgetType>}
        widgetId={(widget as StorefrontWidget<SquareWidgetType>).widget.name}
      />
    ),

    BUBBLE_LINK: (
      <BubbleLinkWidget
        widget={widget as StorefrontWidget<BubbleLinkWidgetType>}
        widgetId={(widget as StorefrontWidget<BubbleLinkWidgetType>).widget.name}
      />
    ),

    CIRCLE_LINK: (
      <CircleLinkWidgetHandler
        widget={widget as StorefrontWidget<CircleLinkWidgetType>}
        widgetId={(widget as StorefrontWidget<CircleLinkWidgetType>).widget.name}
      />
    ),

    SUMMARY_BOXES_LINK: (
      <SummaryBoxesWidgetHandler
        widget={widget as StorefrontWidget<SummaryBoxesWidgetType>}
        widgetId={(widget as StorefrontWidget<SummaryBoxesWidgetType>).widget.name}
      />
    ),

    WIDGET_CTA: (
      <WidgetCTA
        widget={widget as StorefrontWidget<WidgetCTAWidgetType>}
        widgetId={(widget as StorefrontWidget<WidgetCTAWidgetType>).widget.name}
      />
    ),

    CONTENT_BOX: (
      <ContentBox
        widget={widget as StorefrontWidget<ContentBoxWidgetType>}
        widgetId={(widget as StorefrontWidget<ContentBoxWidgetType>).widget.name}
      />
    ),

    IMAGE_GALLERY: (
      <WidgetImageGallery
        widget={widget as StorefrontWidget<ImageGalleryWidgetType>}
        widgetId={(widget as StorefrontWidget<ImageGalleryWidgetType>).widget.name}
      />
    ),

    IMAGE: (
      <ImageWidget
        widget={widget as StorefrontWidget<ImageWidgetType>}
        widgetId={(widget as StorefrontWidget<ImageWidgetType>).widget.name}
      />
    ),

    ACCORDION_ITEM: (
      <WidgetAccordionItem
        widget={widget as StorefrontWidget<AccordionWidgetType>}
        widgetId={(widget as StorefrontWidget<AccordionWidgetType>).widget.name}
      />
    ),

    WIDGET_VIDEO: (
      <WidgetVideoSection
        widget={widget as StorefrontWidget<VideoWidgetType>}
        widgetId={(widget as StorefrontWidget<VideoWidgetType>).widget.name}
      />
    ),

    WIDGET_EMBEDDED_VIDEO: (
      <WidgetEmbeddedVideoSection
        widget={widget as StorefrontWidget<EmbeddedVideoWidgetType>}
        widgetId={(widget as StorefrontWidget<EmbeddedVideoWidgetType>).widget.name}
      />
    ),

    GOOGLE_MAP_WIDGET: (
      <MapWidget
        widget={widget as StorefrontWidget<MapWidgetType>}
        widgetId={(widget as StorefrontWidget<MapWidgetType>).widget.name}
      />
    ),

    // From Stenlunds
    CIRCLE_LINK_1: (
      <CircleLink1Widget
        widget={widget as StorefrontWidget<CircleLinkWidgetType>}
        widgetId={(widget as StorefrontWidget<CircleLinkWidgetType>).widget.name}
      />
    ),

    SUMMARY_BOXES_BUTTON: (
      <SummaryBoxesButtonWidget
        widget={widget as StorefrontWidget<SummaryBoxesButtonWidgetType>}
        widgetId={(widget as StorefrontWidget<SummaryBoxesButtonWidgetType>).widget.name}
      />
    ),

    SUMMARY_BOXES_BUTTON_1: (
      <SummaryBoxesButton1Widget
        widget={widget as StorefrontWidget<SummaryBoxesButtonWidgetType>}
        widgetId={(widget as StorefrontWidget<SummaryBoxesButtonWidgetType>).widget.name}
      />
    ),

    SUMMARY_BOXES_BUTTON_2: (
      <SummaryBoxesButton2Widget
        widget={widget as StorefrontWidget<SummaryBoxesButtonWidgetType>}
        widgetId={(widget as StorefrontWidget<SummaryBoxesButtonWidgetType>).widget.name}
      />
    ),

    ARTICLE_SIZED_BOXES: (
      <ArticleSizedBoxesWidget
        widget={widget as StorefrontWidget<ArticleSizedBoxesWidgetType>}
        widgetId={(widget as StorefrontWidget<ArticleSizedBoxesWidgetType>).widget.name}
      />
    ),

    ARTICLE_BOXES: (
      <ArticleBoxesWidget
        widget={widget as StorefrontWidget<ArticleBoxesWidgetType>}
        widgetId={(widget as StorefrontWidget<ArticleBoxesWidgetType>).widget.name}
      />
    ),

    ARTICLE_BOXES_1: (
      <ArticleBoxes1Widget
        widget={widget as StorefrontWidget<ArticleBoxesWidgetType>}
        widgetId={(widget as StorefrontWidget<ArticleBoxesWidgetType>).widget.name}
      />
    ),

    ARTICLE_BOXES_2: (
      <ArticleBoxes2Widget
        widget={widget as StorefrontWidget<ArticleBoxesWidgetType>}
        widgetId={(widget as StorefrontWidget<ArticleBoxesWidgetType>).widget.name}
      />
    ),

    COLORED_ITEMS: (
      <ColoredItemsWidget
        widget={widget as StorefrontWidget<ColoredItemsWidgetType>}
        widgetId={(widget as StorefrontWidget<ColoredItemsWidgetType>).widget.name}
      />
    ),

    DIVIDER_WIDGET: (
      <DividerWidget
        widget={widget as StorefrontWidget<DividerWidgetType>}
        widgetId={(widget as StorefrontWidget<DividerWidgetType>).widget.name}
      />
    ),

    TWO_COLUMNS: (
      <TwoColumnsWidget
        widget={widget as StorefrontWidget<TwoColumnsWidgetType>}
        widgetId={(widget as StorefrontWidget<TwoColumnsWidgetType>).widget.name}
      />
    ),

    JOINED_WIDGET: (
      <JoinedWidget
        joinedFactory={(w) => sharedWidgetsFactory(w, opts)}
        widget={widget as StorefrontWidget<JoinedWidgetType>}
        widgetId={(widget as StorefrontWidget<JoinedWidgetType>).widget.name}
      />
    ),

    CATEGORY_TREE: <PlaceholderWidget widgetId="CATEGORY_TREE" />,
    CATEGORY_TOOLBAR: <PlaceholderWidget widgetId="CATEGORY_TOOLBAR" />,
    PRODUCT_LIST: <PlaceholderWidget widgetId="PRODUCT_LIST" />,
    PRODUCT_LIST_1: <PlaceholderWidget widgetId="PRODUCT_LIST_1" />,
    CUSTOM_WIDGET_ID: <PlaceholderWidget widgetId="CUSTOM_WIDGET_ID" />,
    WIDGET_RELATED_PRODUCTS: <PlaceholderWidget widgetId="WIDGET_RELATED_PRODUCTS" />,
    WIDGET_TAG_PRODUCTS: <PlaceholderWidget widgetId="WIDGET_TAG_PRODUCTS" />,
    ...opts.extensions
  }

  return dictionary[widget.type as unknown as StorefrontWidgetTypeHandler]
}

import { createCn } from 'shared-utils'

export const HERO_CAROUSEL_WIDGET_CLASSNAME = 'hero-carousel-widget'
export const heroCarouselCn = createCn(HERO_CAROUSEL_WIDGET_CLASSNAME)

export const HERO_CAROUSEL_WIDGET_CN = {
  SECTION: 'section',
  SECTION_CONTAINER: 'section_container',
  SECTION_TITLE: 'section_title',
  SECTION_TITLE_CONTAINER: 'section_title_container',
  SECTION_TITLE_DECORATION: 'section_title_decoration',
  ITEMS_WRAPPER: 'items_wrapper',
  ITEM_WRAPPER: 'item_wrapper',
  ITEM_CONTENT_CONTAINER: 'item_content_container',
  ITEM_SLOGAN: 'item_slogan',
  ITEM_TITLE: 'item_title',
  ITEM_DIVIDER: 'item_divider',
  ITEM_SUB: 'item_sub',
  ITEM_CTA: 'item_cta',
  ITEM_OVERLAY: 'item_overlay'
} as const
